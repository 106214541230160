<template>
	<div>
		<b-row>
			<b-col cols="12">
				<statistic-card-horizontal
					icon="UserIcon"
					color="info"
					:statistic="providerName"
					statistic-title="Proveedor"
				/>
			</b-col>

			<b-col cols="12">
				<validation-observer ref="refForm">
					<b-form
						@submit.prevent="onSubmit"
						@reset.prevent="resetForm"
					>
						<b-row>
							<b-col md="12">
								<b-card
									footer-tag="footer"
									class="mb-0"
								>
									<b-row>
										<b-col cols="12">
											<b-card no-body>
												<b-card-title class="text-primary">ÍNDICES PRINCIPALES</b-card-title>
												<b-row>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="security">
															<strong>SEGURIDAD</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															rules="required"
															name="Seguridad"
														>
															<vue-cleave
																id="security"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.security"
																:options="cleaveOptions"
																@input="$emit('input', formData.security)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="productivity">
															<strong>PRODUCTIVIDAD</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															rules="required"
															name="Productividad"
														>
															<vue-cleave
																id="productivity"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.productivity"
																:options="cleaveOptions"
																@input="$emit('input', formData.productivity)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
												</b-row>
											</b-card>
										</b-col>
										<b-col cols="12">
											<b-card no-body>
												<b-card-title class="text-primary">ÍNDICES DE CALIBRACIÓN</b-card-title>
												<b-row>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="labor_risk">
															<strong>RIESGO LABORAL</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Riesgo Laboral"
														>
															<vue-cleave
																id="labor_risk"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.labor_risk"
																:options="cleaveOptions"
																@input="$emit('input', formData.labor_risk)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="colpa">
															<strong>COLPA</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Colpa"
														>
															<vue-cleave
																id="colpa"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.colpa"
																:options="cleaveOptions"
																@input="$emit('input', formData.colpa)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="financial_risk">
															<strong>RIESGO FINANCIERO</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Riesgo Financiero"
														>
															<vue-cleave
																id="financial_risk"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.financial_risk"
																:options="cleaveOptions"
																@input="$emit('input', formData.financial_risk)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="calibration">
															<strong>CALIBRACIÓN</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Calibración"
														>
															<vue-cleave
																id="calibration"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.calibration"
																:options="cleaveOptions"
																@input="$emit('input', formData.calibration)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>

													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<h5 class="text-info">DEUDA PENDIENTE</h5>
														<label for="debt_due">
															<strong>SBS/MICROF.</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Deuda Pendiente"
														>
															<vue-cleave
																id="debt_due"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.debt_due"
																:options="cleaveOptions"
																@input="$emit('input', formData.debt_due)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<h5 class="text-info">DEUDA VENCIDA</h5>
														<label for="overdue_debt">
															<strong>SBS/MICROF.</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Deuda Vencida"
														>
															<vue-cleave
																id="overdue_debt"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.overdue_debt"
																:options="cleaveOptions"
																@input="$emit('input', formData.overdue_debt)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>

													<b-col cols="12">
														<h5 class="text-info">OTRAS DEUDAS VENCIDAS</h5>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="protests">
															<strong>PROTESTOS</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Protestos"
														>
															<vue-cleave
																id="protests"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.protests"
																:options="cleaveOptions"
																@input="$emit('input', formData.protests)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="unpaid">
															<strong>DOC IMPAGO</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Doc Impago"
														>
															<vue-cleave
																id="unpaid"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.unpaid"
																:options="cleaveOptions"
																@input="$emit('input', formData.unpaid)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="labor">
															<strong>LABORAL</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Laboral"
														>
															<vue-cleave
																id="labor"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.labor"
																:options="cleaveOptions"
																@input="$emit('input', formData.labor)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="tributaria">
															<strong>TRIBUTARIA</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Tributaria"
														>
															<vue-cleave
																id="tributaria"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.tributaria"
																:options="cleaveOptions"
																@input="$emit('input', formData.tributaria)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
												</b-row>
											</b-card>
										</b-col>
										<b-col cols="12">
											<b-card no-body>
												<b-card-title class="text-primary">ÍNDICES INFORMATIVOS</b-card-title>
												<b-row>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="rotation">
															<strong>ROTACIÓN</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Rotación"
														>
															<vue-cleave
																id="rotation"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.rotation"
																:options="cleaveOptions"
																@input="$emit('input', formData.rotation)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="penality_number">
															<strong>PENALIDADES (CANTIDAD)</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Penalidades Cantidad"
														>
															<vue-cleave
																id="penality_number"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.penality_number"
																:options="cleaveIntegerOptions"
																@input="$emit('input', formData.penality_number)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
													<b-col
														cols="12"
														md="3"
														class="mb-2"
													>
														<label for="penality_amount">
															<strong>PENALIDADES (MONTO)</strong>
														</label>
														<validation-provider
															#default="{ errors }"
															:rules="isRequired ? 'required' : ''"
															name="Penalidades Monto"
														>
															<vue-cleave
																id="penality_amount"
																:class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
																v-model="formData.penality_amount"
																:options="cleaveOptions"
																@input="$emit('input', formData.penality_amount)"
																placeholder="-"
															/>

															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-col>
												</b-row>
											</b-card>
										</b-col>
									</b-row>

									<template #footer>
										<b-row>
											<b-col cols="12">
												<!-- BUTTON SUBMIT -->
												<b-button-loading
													text="EVALUAR"
													type="submit"
													:processing="processing"
												/>
											</b-col>
										</b-row>
									</template>
								</b-card>
							</b-col>
						</b-row>
					</b-form>
				</validation-observer>
			</b-col>
		</b-row>
	</div>
</template>


<script>
import useNotifications from "@notifications"
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import performanceStoreModule from "../performanceStoreModule"
import VueCleave from "vue-cleave-component"
import { required } from "@validations"
import vSelect from "vue-select"
import store from "@/store"
import router from "@/router"

export default {
	components: {
		StatisticCardHorizontal,

		ValidationProvider,
		ValidationObserver,

		BButtonLoading,

		VueCleave,

		required,
		vSelect
	},
	setup() {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)
		const providerName = ref("Proveedor")
		const processing = ref(false)
		const isRequired = ref(false)
		const options = ref({})

		const performanceData = ref(null)

		const blankData = {
			security: null,
			productivity: null,
			labor_risk: null,
			colpa: null,
			financial_risk: null,
			calibration: null,
			debt_due: null,
			overdue_debt: null,
			protests: null,
			unpaid: null,
			labor: null,
			tributaria: null,
			rotation: null,
			penality_number: null,
			penality_amount: null
		}

		const formData = ref(JSON.parse(JSON.stringify(blankData)))

		const cleaveOptions = {
			numeral: true, // Permite solo números
			numeralThousandsGroupStyle: "thousand", // Separadores de miles
			numeralDecimalMark: ".", // Punto como separador decimal
			delimiter: ",", // Coma como separador de miles
			numeralDecimalScale: 4
		}

		const cleaveIntegerOptions = {
			numeral: true, // Permite solo números
			numeralThousandsGroupStyle: "thousand", // Separadores de miles
			numeralDecimalMark: ".", // Punto como separador decimal
			delimiter: ",", // Coma como separador de miles
			numeralDecimalScale: 0
		}

		const PERFORMANCE_APP_STORE_MODULE_NAME = "app-performance"

		// REGISTER MODULE
		if (!store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
			store.registerModule(
				PERFORMANCE_APP_STORE_MODULE_NAME,
				performanceStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
				store.unregisterModule(PERFORMANCE_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			fetchPerformance()
		})

		const fetchPerformance = () => {
			store
				.dispatch("app-performance/fetchPerformance", {
					id: router.currentRoute.params.id
				})
				.then((response) => {
					performanceData.value = response.data.performance
					setData(performanceData.value)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
		}

		const formatNumber = (value, pct = false) => {
			if (!value) return null

			if (pct) return (parseFloat(value) * 100).toFixed(2)
			return parseFloat(value).toFixed(2)
		}

		const setData = (data) => {
			formData.value = {
				security: formatNumber(data.security),
				productivity: formatNumber(data.productivity, true),
				labor_risk: formatNumber(data.labor_risk, true),
				colpa: formatNumber(data.colpa, true),
				financial_risk: formatNumber(data.financial_risk),
				calibration: formatNumber(data.calibration),
				debt_due: formatNumber(data.debt_due),
				overdue_debt: formatNumber(data.overdue_debt),
				protests: formatNumber(data.protests),
				unpaid: formatNumber(data.unpaid),
				labor: formatNumber(data.labor),
				tributaria: formatNumber(data.tributaria),
				rotation: formatNumber(data.rotation, true),
				penality_number: formatNumber(data.penality_number),
				penality_amount: formatNumber(data.penality_amount)
			}

			providerName.value = data.provider.alias || data.provider.name
		}

		const onSubmit = async () => {
			/* 	isRequired.value = true */

			const isValid = await refForm.value.validate()
			if (!isValid) {
				toastNotification("Complete los campos requeridos", false)
				return
			}

			processing.value = true

			const id = performanceData.value.id

			const payload = {
				...formData.value,
				productivity: formatNumber(formData.value.productivity) / 100 || null,
				labor_risk: formatNumber(formData.value.labor_risk) / 100 || null,
				colpa: formatNumber(formData.value.colpa) / 100 || null,
				rotation: formatNumber(formData.value.rotation) / 100 || null
			}

			store
				.dispatch("app-performance/updatePerformance", { id, payload })
				.then((response) => {
					isRequired.value = false
					toastNotification(response.data.message)
					router.push({ name: "performances-list" })
				})
				.catch((err) => {
					const message =
						err.response.data.message || "Error al evaluar desemepeño"
					toastNotification(message, false)
				})
				.finally(() => (processing.value = false))
		}

		return {
			// DATA
			processing,
			formData,
			options,
			isRequired,
			providerName,

			// REFS
			refForm,
			cleaveOptions,
			cleaveIntegerOptions,

			// METHODS
			onSubmit
		}
	}
}
</script>

<style scoped>
.is-invalid {
	border-color: #dc3545;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>