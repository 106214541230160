var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('statistic-card-horizontal',{attrs:{"icon":"UserIcon","color":"info","statistic":_vm.providerName,"statistic-title":"Proveedor"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"refForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-0",attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button-loading',{attrs:{"text":"EVALUAR","type":"submit","processing":_vm.processing}})],1)],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"text-primary"},[_vm._v("ÍNDICES PRINCIPALES")]),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"security"}},[_c('strong',[_vm._v("SEGURIDAD")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Seguridad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"security","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.security)}},model:{value:(_vm.formData.security),callback:function ($$v) {_vm.$set(_vm.formData, "security", $$v)},expression:"formData.security"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"productivity"}},[_c('strong',[_vm._v("PRODUCTIVIDAD")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Productividad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"productivity","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.productivity)}},model:{value:(_vm.formData.productivity),callback:function ($$v) {_vm.$set(_vm.formData, "productivity", $$v)},expression:"formData.productivity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"text-primary"},[_vm._v("ÍNDICES DE CALIBRACIÓN")]),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"labor_risk"}},[_c('strong',[_vm._v("RIESGO LABORAL")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Riesgo Laboral"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"labor_risk","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.labor_risk)}},model:{value:(_vm.formData.labor_risk),callback:function ($$v) {_vm.$set(_vm.formData, "labor_risk", $$v)},expression:"formData.labor_risk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"colpa"}},[_c('strong',[_vm._v("COLPA")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Colpa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"colpa","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.colpa)}},model:{value:(_vm.formData.colpa),callback:function ($$v) {_vm.$set(_vm.formData, "colpa", $$v)},expression:"formData.colpa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"financial_risk"}},[_c('strong',[_vm._v("RIESGO FINANCIERO")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Riesgo Financiero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"financial_risk","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.financial_risk)}},model:{value:(_vm.formData.financial_risk),callback:function ($$v) {_vm.$set(_vm.formData, "financial_risk", $$v)},expression:"formData.financial_risk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"calibration"}},[_c('strong',[_vm._v("CALIBRACIÓN")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Calibración"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"calibration","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.calibration)}},model:{value:(_vm.formData.calibration),callback:function ($$v) {_vm.$set(_vm.formData, "calibration", $$v)},expression:"formData.calibration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('h5',{staticClass:"text-info"},[_vm._v("DEUDA PENDIENTE")]),_c('label',{attrs:{"for":"debt_due"}},[_c('strong',[_vm._v("SBS/MICROF.")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Deuda Pendiente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"debt_due","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.debt_due)}},model:{value:(_vm.formData.debt_due),callback:function ($$v) {_vm.$set(_vm.formData, "debt_due", $$v)},expression:"formData.debt_due"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('h5',{staticClass:"text-info"},[_vm._v("DEUDA VENCIDA")]),_c('label',{attrs:{"for":"overdue_debt"}},[_c('strong',[_vm._v("SBS/MICROF.")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Deuda Vencida"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"overdue_debt","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.overdue_debt)}},model:{value:(_vm.formData.overdue_debt),callback:function ($$v) {_vm.$set(_vm.formData, "overdue_debt", $$v)},expression:"formData.overdue_debt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-info"},[_vm._v("OTRAS DEUDAS VENCIDAS")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"protests"}},[_c('strong',[_vm._v("PROTESTOS")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Protestos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"protests","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.protests)}},model:{value:(_vm.formData.protests),callback:function ($$v) {_vm.$set(_vm.formData, "protests", $$v)},expression:"formData.protests"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"unpaid"}},[_c('strong',[_vm._v("DOC IMPAGO")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Doc Impago"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"unpaid","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.unpaid)}},model:{value:(_vm.formData.unpaid),callback:function ($$v) {_vm.$set(_vm.formData, "unpaid", $$v)},expression:"formData.unpaid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"labor"}},[_c('strong',[_vm._v("LABORAL")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Laboral"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"labor","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.labor)}},model:{value:(_vm.formData.labor),callback:function ($$v) {_vm.$set(_vm.formData, "labor", $$v)},expression:"formData.labor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"tributaria"}},[_c('strong',[_vm._v("TRIBUTARIA")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Tributaria"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"tributaria","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.tributaria)}},model:{value:(_vm.formData.tributaria),callback:function ($$v) {_vm.$set(_vm.formData, "tributaria", $$v)},expression:"formData.tributaria"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"text-primary"},[_vm._v("ÍNDICES INFORMATIVOS")]),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"rotation"}},[_c('strong',[_vm._v("ROTACIÓN")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Rotación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"rotation","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.rotation)}},model:{value:(_vm.formData.rotation),callback:function ($$v) {_vm.$set(_vm.formData, "rotation", $$v)},expression:"formData.rotation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"penality_number"}},[_c('strong',[_vm._v("PENALIDADES (CANTIDAD)")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Penalidades Cantidad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"penality_number","options":_vm.cleaveIntegerOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.penality_number)}},model:{value:(_vm.formData.penality_number),callback:function ($$v) {_vm.$set(_vm.formData, "penality_number", $$v)},expression:"formData.penality_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"penality_amount"}},[_c('strong',[_vm._v("PENALIDADES (MONTO)")])]),_c('validation-provider',{attrs:{"rules":_vm.isRequired ? 'required' : '',"name":"Penalidades Monto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{class:['form-control', errors.length > 0 ? 'is-invalid' : ''],attrs:{"id":"penality_amount","options":_vm.cleaveOptions,"placeholder":"-"},on:{"input":function($event){return _vm.$emit('input', _vm.formData.penality_amount)}},model:{value:(_vm.formData.penality_amount),callback:function ($$v) {_vm.$set(_vm.formData, "penality_amount", $$v)},expression:"formData.penality_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }